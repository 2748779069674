<template>
    <modal ref="modalCrearEntrega" titulo="Crear Entrega" tamano="modal-lg" @guardar="crearEntrega">
        <ValidationObserver ref="form1">
            <div class="row mx-0">
                <div class="col-auto" />
                <div class="col-auto" />
                <div class="col">
                    <ValidationProvider v-slot="{ errors }" rules="required" name="titulo">
                        <el-input
                        v-model="model.titulo"
                        class="w-100 input-name mt-4"
                        placeholder="Titulo de la Entrega"
                        maxlength="120"
                        show-word-limit
                        />
                        <span class="v-error">{{ errors[0] }}</span>
                    </ValidationProvider>
                    <p class="f-15 text-gris mt-3 pb-2 pl-2">
                        Descripcion de la entrega
                    </p>
                    <ValidationProvider v-slot="{ errors }" rules="required" name="descripcion">
                       
                            <el-input
                            v-model="model.descripcion"
                            placeholder="Por favor digete la descripción..."
                            maxlength="1000"
                            show-word-limit
                            type="textarea" :rows="10" 
                            class="w-100 area-radius  text-entregas input-pr"
                            />
                       
                            <span class="v-error">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
                <div class="col-auto" />
                <div class="col-auto" />
            </div>
        </ValidationObserver>
    </modal>
</template>
    
<script>
import Cursos from '~/services/cursos/gestionar'
export default {
    data(){
        return {
            model: {
                titulo: '',
                descripcion: ''
            }

        }
    },
    computed:{
        id_curso(){
            return Number(this.$route.params.id_curso)
        }
    },
    methods: {
        toggle(){
            this.limpiar()
            this.$refs.modalCrearEntrega.toggle();
        },
        async crearEntrega(){
            try {
                const valid = this.$refs.form1.validate()

                if(valid){
                    const {data} = await Cursos.crearEntrega(this.id_curso, this.model)
                    this.notify('Éxito, Entrega almacenada correctamente','success')
                    this.$refs.modalCrearEntrega.toggle();
                    this.$emit('update')
                }


            } catch (error){
                this.error_catch(error)
            }
        },
        limpiar(){
            this.model = {
                titulo:'',
                descripcion:''
            }
            this.$refs.form1.reset()
        }
    }
}
</script>

<style lang="scss" scoped>

</style>